@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://use.typekit.net/nfe0dwz.css");

body {
    font-family: "indivisible", sans-serif;
    letter-spacing: 0.5px;
}

header {
    animation: 500ms ease-out 0s 1 FadeIn;
}

@keyframes FadeIn {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: 0.8px;
}